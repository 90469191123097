import { render, staticRenderFns } from "./PlanList.vue?vue&type=template&id=62f57c12&scoped=true&"
import script from "./PlanList.vue?vue&type=script&lang=js&"
export * from "./PlanList.vue?vue&type=script&lang=js&"
import style0 from "./PlanList.vue?vue&type=style&index=0&id=62f57c12&lang=scss&scoped=true&"
import style1 from "./PlanList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62f57c12",
  null
  
)

export default component.exports