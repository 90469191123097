<template>
    <b-card>
        <b-card-header>
          <b-card-actions>
            <div class="demo-inline-spacing">
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"  variant="outline-primary" @click="showModal">
                  Buy Bandwidth
                </b-button>
            </div>
          </b-card-actions>
        </b-card-header>

        
        <b-overlay
          :show="show"
          rounded="xl"
          :variant="variant"
          :opacity="opacity"
          :blur="blur"
        >
        <b-card-body>
          <div class="d-flex justify-content-between  flex-wrap">

            <!-- sorting  -->
            <b-form-group
              label="Sort"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="2"
              label-for="sortBySelect"
              class="mr-1 mb-md-0"
            >
              <b-input-group
                size="sm"
              >
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      none
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Asc
                  </option>
                  <option :value="true">
                    Desc
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Filter"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          ref="table"
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="data"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(status)="data">
            <b-badge v-if="data.item.active" variant="success">Actived</b-badge>
            <b-badge v-else variant="info">Not Actived</b-badge>
          </template>
          <template #cell(amount)=data>
            {{Number(data.item.amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}}
          </template>
          <template #cell(date_created)=data>
            {{convertUTCDateToLocalDate(data.item.date_created)}}
          </template>
          <template #cell(date_expired)=data>
            {{data.item.date_expired ? convertUTCDateToLocalDate(data.item.date_expired) : ''}}
          </template>
          <template #cell(ptype)=data>
            <b-badge v-if="data.item.active" variant="success">Actived</b-badge>
            <b-badge v-else variant="info">Buy new bandwidth</b-badge>
          </template>
          <template #cell(oid)=data>
            <!-- {{data.item.oid +' ' + data.item.price+"$/GB"+' '+getDate(data.item.date_expired)}} -->
            {{getInfoPlan(data.item)}}
          </template>
          <template #cell(current_bandwidth)=data>
            {{formatBytes(data.item.current_bandwidth,false)}}
            
          </template>

          <template #cell(bandwidth)=data>
            {{formatBytes(data.item.bandwidth)}}
          </template>

        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

          <!-- page length -->
          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
        </b-overlay>
        <b-modal
          id="modal-buy-bw"
          ok-title="Confirm"
          cancel-title="Cancel"
          size="md"
          title="Buy Bandwidth"
          @ok="buyBW"
          no-close-on-backdrop
          :ok-disabled = "ok_dis"
        >
        <b-form @submit.prevent>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Bandwidth"
                  label-for="h-first-name"
                  label-cols-md="3"
                  :description="desc"
                >
                  <b-form-input
                    id="h-first-name"
                    placeholder="Range: 1->1000"
                    type="number"
                    v-model="tempPost.bw"
                    :min="1"
                    :max="1000"
                    :step="1"
                  />
                </b-form-group>
              </b-col>
              
              <!-- <b-col cols="12">
                <b-form-group
                  label="Type"
                  label-for="h-first-name"
                  label-cols-md="3"
                >
                  
                    <b-form-radio
                      v-model="tempPost.ptype"
                      name="some-radios"
                      value="1"
                    >
                      Buy new bandwith
                    </b-form-radio>

                    <b-form-radio
                      v-model="tempPost.ptype"
                      name="some-radios"
                      value="0"
                    >
                      Add more bandwith
                    </b-form-radio>
                </b-form-group>
              </b-col>

              <b-col cols="12" v-if="tempPost.ptype == 0">
                <b-form-group
                  label="Bandwidth"
                  label-for="h-first-name"
                  label-cols-md="3"
                  :description="max_bw"
                >
                  <v-select
                    v-model="tempPost.oid"
                    label="oid"
                    :options="data"
                    
                  />
                </b-form-group>
                
              </b-col>
              <b-col cols="12"> -->
                <!-- <b-form-group
                  label="Month"
                  label-for="h-first-name"
                  label-cols-md="3"
                  v-if="tempPost.ptype == 1"
                >
                  <v-select
                    v-model="tempPost.month"
                    :options="month"
                  />
                </b-form-group>
              </b-col> -->
            </b-row>
          </b-form>
         
        </b-modal>
        <b-card-body>
          <iframe src="https://guide.fastproxy.vip/priceproxy.html" frameborder="0" style="overflow:hidden;height:600px;width:100%" width="100%"></iframe>
        </b-card-body>
        
    </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BOverlay, BCard, BTooltip, BFormRadio,
  BRow,BCol, BCardHeader, BForm, BImg
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

import pricing from './Pricing.vue'
import RepositoryFactory from '../../../api/RepositoryFactory'
import nportsRepository from '@/api/users/nportsRepository'
const NPlansRepository = RepositoryFactory.get('nplans')



const tempPObj = {
  bw: 100,
  ptype: 0,
  oid: {},
  month: 1
}

//import { PayPalIcon } from 'vue-brand-icons'
export default {
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BOverlay,
    BCard,
    BTooltip,
    BFormRadio,
    BRow,
    BCol,
    BCardHeader, 
    BForm,
    vSelect,
    BImg,
    'pricing': pricing
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: [],
      show:false,
      variant: 'light',
      opacity: 0.85,
      blur: '2px',
      perPage: 20,
      pageOptions: [5, 10, 20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'created_at',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      //desc: "",
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'oid', label: 'Order ID', sortable: true },
        { key: 'current_bandwidth', label: 'Current Bandwidth', sortable: true },
        { key: 'bandwidth', label: 'Total Bandwidth', sortable: true },
        { key: 'request', label: 'Total Request', sortable: true },
        //{ key: 'ptype', label: 'Type', sortable: true },
        { key: 'date_created', label: 'Buy Time', sortable: true },
        { key: 'date_expired', label: 'Expired Time', sortable: true },
        // { name:"status", label: 'status', sortable: true },
        "status"
      ],
      /* eslint-disable global-require */
      /* eslint-disable global-require */
      status: [
        {
          0: 'light-success', 1: 'light-danger',
        },
        {
          0: 'Actived', 1: 'Waiting',
        },
      ],

      tempPost: {
        bw: 10,
        ptype: 1,
        oid: {},
        month: 1
      },
      month: [1,2,3],
      max_bw: "",
      ok_dis: false,

      psettings: this.$store.getters['auth/settings'],
    }
  },
  created(){
    
    this.show = false
    NPlansRepository.fetch().then(rs=>{
      this.data = rs.data.data.sort()
      //console.log(this.data)
    })
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    desc(){
      let bw_ = parseInt(this.tempPost.bw)
      let cur_plan = this.getCurrentPlan(bw_)
      let month = 1
      // if(parseInt(this.tempPost.ptype) == 0){
      //   month = parseInt(this.tempPost.oid.month)
      // }else{
      //   month = parseInt(this.tempPost.month)
      // }
      
      //console.log(month)
      let price = 0
      let amount = 0

      if(parseInt(this.tempPost.ptype) == 0){
        price = this.tempPost.oid.price
        amount = price * bw_
        let cr_bw_pl = this.getPlanFromPrice(this.tempPost.oid.price)
        //console.log(cr_bw_pl)
        if(cr_bw_pl >= 10){
          this.max_bw = `You can add maxium ${cr_bw_pl-1}GB for this plan`
          //console.log(bw_,this.max_bw)
          if(bw_ > cr_bw_pl)
          {
            this.ok_dis = true
          }else{
            this.ok_dis = false
          }
        }else{
          this.max_bw = "You cannot add bandwith for this plan"
          this.ok_dis = true
        }

        
      }else{
        this.ok_dis = false
        let ex = 0
        // switch(month){
        //   case 1:
        //     ex = 0
        //     break
        //   case 2:
        //     ex = 0.1
        //     break
        //   case 3:
        //     ex = 0.2
        //     break
        // }
        ex = 0

        if(bw_ >= 5000){
          price = this.psettings.T5000 + ex
          amount = (this.psettings.T5000 + ex) *bw_
        }


        else if(bw_ >= 4000 && bw_ < 5000){
          price = this.psettings.T4000 + ex
          amount = (this.psettings.T4000 + ex) *bw_
        }

        else if(bw_ >= 3000 && bw_ < 4000){
          price = this.psettings.T3000 + ex
          amount = (this.psettings.T3000 + ex) *bw_
        }

        else if(bw_ >= 2000 && bw_ < 3000){
          price = this.psettings.T2000 + ex
          amount = (this.psettings.T2000 + ex) *bw_
        }

        else if(bw_ >= 1000 && bw_ < 2000){
          price = this.psettings.T1000 + ex
          amount = (this.psettings.T1000 + ex) *bw_
        }
        else if(bw_ >= 500 && bw_ < 1000)
        {
          price =  this.psettings.T500 + ex
          amount = (this.psettings.T500 + ex) *bw_
        }
        else if(bw_ >= 200 && bw_ < 500)
        {
          price =  this.psettings.T200 + ex
          amount = (this.psettings.T200 + ex)*bw_
        }
        else if(bw_ >= 100 && bw_ < 200)
        {
          price =  this.psettings.T100 + ex
          amount = (this.psettings.T100 + ex ) *bw_
        }
        else if(bw_ >= 50 && bw_ < 100)
        {
          price =  this.psettings.T50 + ex
          amount = (this.psettings.T50 + ex) *bw_
        }
        else if(bw_ >= 10 && bw_ < 50)
        {
          price =  this.psettings.T10 + ex
          amount = (this.psettings.T10 + ex ) *bw_
        }
        else if(bw_ > 0 && bw_ < 10)
        {
          price =  this.psettings.T0 + ex
          amount = (this.psettings.T0 + ex ) *bw_
        }
      }
      return `Bandwith: ${bw_}GB/1 Month Amount:  ${amount.toFixed(2) }$ Price: ${price}/GB`
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.data.length
  },
  methods: {
    showModal(){
      this.tempPost = Object.assign({},tempPObj)
      //console.log(this.data)
      if(this.data.length > 0){
        this.tempPost.ptype = 0
        this.tempPost.oid = this.data[0]
        this.tempPost.month = this.data[0].month
        //console.log(this.tempPost.month)
      }else{
        this.tempPost.ptype = 1
        this.tempPost.oid = {}
        this.tempPost.month = 1
      }
      this.tempPost.ptype = 1
      this.$bvModal.show('modal-buy-bw')
    },
    formatDateTime(d){
      let date = new Date(d)
      let options = { year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: false}
        return new Intl.DateTimeFormat('vi-VN', options).format(date)
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    convertUTCDateToLocalDate(d) {
      let date = new Date(d)
      return date.toLocaleString('vi-VN')
      return this.formatDateTime(date.toLocaleString());   
      var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

      var offset = date.getTimezoneOffset() / 60;
      var hours = date.getHours();

      newDate.setHours(hours - offset);

      return this.formatDateTime(newDate);   
    },
    buyBW(){
      NPlansRepository.buyBW(this.tempPost).then(rs=>{
        //console.log(parseInt(rs.data.data.ptype) )
        if(rs.data.success){
          if(parseInt(rs.data.data.ptype) === 1){
            this.data.push(rs.data.data)
            this.$store.dispatch('auth/get_uinfo')
          }else{
            //console.log(rs.data.data)
            let index = this.data.findIndex(x=>x._id == rs.data.data._id)
            //console.log(index)
            this.data[index] = Object.assign({}, rs.data.data)
            this.$refs.table.refresh()
            this.$store.dispatch('auth/get_uinfo')
          }
        }else{
          this.$bvToast.toast(rs.data.errMsg, {
            title: 'Error',
            variant: 'danger',
            solid: false,
          })
        }
        

      })
    },
    getDate(c){
      let d = new Date(c)
      let t = d.getTime() - new Date(Date.now()).getTime()


      let diffDay =  (t / (1000 * 3600 * 24)).toFixed(0)
      return diffDay+" days"
    },
    formatBytes(bytes, t=true) {
      var marker = 1000; // Change to 1000 if required
      var decimal = 2; // Change as required
      var kiloBytes = marker; // One Kilobyte is 1024 bytes
      var megaBytes = marker * marker; // One MB is 1024 KB
      var gigaBytes = marker * marker * marker; // One GB is 1024 MB
      var teraBytes = marker * marker * marker * marker; // One TB is 1024 GB
      // return bytes if less than a KB
      if(t){
        if(bytes < kiloBytes) return bytes + " Bytes";
        // return KB if less than a MB
        else if(bytes < megaBytes) return(bytes / kiloBytes).toFixed(decimal) + " KB";
        // return MB if less than a GB  ``
        else if(bytes < gigaBytes) return(bytes / megaBytes).toFixed(decimal) + " MB";
        // return GB if less than a TB
        else if(bytes < teraBytes) return (bytes / gigaBytes).toFixed(decimal) + " GB";
        
        else return (bytes / teraBytes).toFixed(decimal) + " TB";
      }else{
        if(bytes < kiloBytes) return bytes + " Bytes";
        // return KB if less than a MB
        else if(bytes < megaBytes) return(bytes / kiloBytes).toFixed(decimal) + " KB";
        // return MB if less than a GB  ``
        else return(bytes / megaBytes).toFixed(decimal) + " MB";
      }
    },
    formatBWtoGB(bytes) {
      var marker = 1000; // Change to 1000 if required
      var decimal = 2; // Change as required
      var kiloBytes = marker; // One Kilobyte is 1024 bytes
      var megaBytes = marker * marker; // One MB is 1024 KB
      var gigaBytes = marker * marker * marker; // One GB is 1024 MB
      var teraBytes = marker * marker * marker * marker; // One TB is 1024 GB
      return (bytes / gigaBytes).toFixed(decimal)
    },
    getCurrentPlan(bw){
      if(bw >= 5000)
        return 5000
      else if(bw >= 4000 && bw < 5000)
        return 4000
      else if(bw >= 3000 && bw < 4000)
        return 3000
      else if(bw >= 2000 && bw < 3000)
        return 2000
      else if(bw >= 1000 && bw < 2000)
        return 1000
      else if(bw >= 500 && bw < 1000)
        return 500
      else if(bw >= 200 && bw < 500)
        return 200
      else if(bw >= 100 && bw < 200)
        return 100
      else if(bw >= 50 && bw < 100)
        return 50
      else if(bw >= 10 && bw < 50)
        return 10
      else if(bw > 0 && bw < 10)
        return 0
    },
    onChangePlan(oid){
      console.log('on change plan')
    },

    getPlanFromPrice(price){
      switch(price){
        case this.psettings.T5000:
          return 5000
          break
        case this.psettings.T4000:
          return 4000
          break
        case this.psettings.T3000:
          return 3000
          break
        case this.psettings.T2000:
          return 2000
          break
        case this.psettings.T1000:
          return 1000
          break
        case this.psettings.T500:
          return 500
          break
        case this.psettings.T200:
          return 200
          break
        case this.psettings.T100:
          return 100
          break
        case this.psettings.T50:
          return 50
          break
        case this.psettings.T10:
          return 10
          break
        case this.psettings.T0:
          return 0
          break

      }
    },  

    getPriceOfPlan(bw_){
      //(bw_)
      if(bw_ >= 5000)
        return this.psettings.T5000
      else if(bw_ >= 4000 && bw_ < 5000)
        return this.psettings.T4000
      else if(bw_ >= 3000 && bw_ < 4000)
        return this.psettings.T3000
      else if(bw_ >= 2000 && bw_ < 3000)
        return this.psettings.T2000
      else if(bw_ == 1000){
        return this.psettings.T1000
      }
      else if(bw_ == 500)
      {
        return this.psettings.T500
      }
      else if(bw_ == 200)
      {
        return this.psettings.T200
      }
      else if(bw_ == 100)
      {
        return this.psettings.T100
      }
      else if(bw_ == 50)
      {
        return this.psettings.T50
      }
      else if(bw_ == 10)
      {
        return this.psettings.T10
      }
      else if(bw_ == 0)
      {
        //console.log(bw_)
        return  this.psettings.T0
      }
    },
    getInfoPlan(data){

      //console.log(data.price)
      //console.log(this.psettings.T0)
      if(data.date_expired)
        return data.oid +' ' + data.price+"$/GB"+' '+this.getDate(data.date_expired)
      else
        return data.oid +' ' + data.price+"$/GB"
    }

  },
}
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/vue-select.scss';
.dark-layout {
  div ::v-deep .card .card-body {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
